@import url('https://fonts.googleapis.com/css2?family=Matemasie&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300..700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #1e1e1e;
}
